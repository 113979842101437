.rc-slider {
  @apply relative flex h-9 w-full touch-none items-center;
}
.rc-slider-rail {
  @apply absolute h-[1px] w-full bg-gray-900 dark:bg-gray-900-dark;
}
.rc-slider-track,
.rc-slider-tracks {
  @apply absolute h-[1px] bg-gray-900 dark:bg-gray-900-dark;
}

.rc-slider-mark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
